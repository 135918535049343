import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import logo from '../../img/logoP.webp'; // Importez directement votre logo
import './generatePdf.scss';

function GeneratePdf({ formValues, rentabilite }) {
  const generatePDF = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const primaryColor = '#18625E';
    const marginX = 20;
    const footerY = 290;

    // Mapping des noms techniques vers des noms lisibles avec accentuation
    const keyMapping = {
      PrixAchat: 'Prix d\'achat',
      apport: 'Apport',
      tauxEmprunt: 'Taux d\'emprunt',
      dureeCredit: 'Durée du crédit',
      loyer: 'Loyer',
      chargesRecuperables: 'Charges récupérables',
      chargesCopropriete: 'Charges de copropriété',
      taxeFonciere: 'Taxe foncière',
      entretien: 'Entretien',
      assurance: 'Assurance',
      coutRenovation: 'Coût de rénovation',
      vacanceLocative: 'Vacance locative',
      mensualiteEmprunt: 'Mensualité du prêt',
      rentabiliteBrute: 'Rentabilité brute',
      rentabiliteNette: 'Rentabilité nette',
      resultatNet: 'Bénéfice/Effort net mensuel',
    };

    // Fonction pour formater les clés
    const formatKey = (key) => keyMapping[key] || key;

    // Fonction pour formater les valeurs avec unités
    const formatValue = (key, value) => {
      if (value === null || value === undefined || value === '') return 'Non renseigné';

      if (['PrixAchat', 'apport', 'coutRenovation', 'loyer'].includes(key)) return `${value} €`;
      if (['tauxEmprunt', 'vacanceLocative'].includes(key)) return `${value} %`;
      if (['dureeCredit'].includes(key)) return `${value} années`;
      if (['chargesRecuperables', 'chargesCopropriete', 'taxeFonciere', 'entretien', 'assurance'].includes(key)) return `${value} € / mois`;
      if (['rentabiliteBrute', 'rentabiliteNette'].includes(key)) return `${value} %`;
      if (['mensualiteEmprunt'].includes(key)) return `${value} €`;

      if (key === 'resultatNet') {
        return value >= 0 ? `Bénéfice net mensuel : ${value} €` : `Effort net mensuel : ${Math.abs(value)} €`;
      }

      return value;
    };

    // Charger le logo et générer le PDF
    const loadLogoAsBase64 = (imagePath, callback) => {
      const img = new Image();
      img.src = imagePath;
      img.onload = () => {
        const targetWidth = 100; // Largeur cible (en pixels)
        const aspectRatio = img.width / img.height;
        const targetHeight = targetWidth / aspectRatio; // Calculer la hauteur pour conserver les proportions

        // Créer un canvas avec une résolution réduite
        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext('2d');

        // Dessiner l'image redimensionnée
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        // Générer une image Base64 avec compression
        const quality = 0.7; // Qualité (entre 0.1 et 1)
        callback(canvas.toDataURL('image/jpeg', quality)); // Format JPEG avec compression
      };
    };

    loadLogoAsBase64(logo, (logoBase64) => {
      // Ajouter le logo avec un effet d'arrondi
      const logoX = 10; // Position X
      const logoY = 10; // Position Y
      const logoWidth = 20; // Largeur
      const logoHeight = 20; // Hauteur
      const borderRadius = 15; // Rayon des coins arrondis

      // Dessiner un rectangle arrondi en arrière-plan
      doc.setDrawColor(primaryColor);
      doc.setFillColor('#ffffff');
      doc.roundedRect(logoX, logoY, logoWidth, logoHeight, borderRadius, borderRadius, 'FD');

      // Ajouter le logo
      doc.addImage(logoBase64, 'WEBP', logoX, logoY, logoWidth, logoHeight);

      // Titre principal
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(24);
      doc.setTextColor(primaryColor);
      doc.text('Rapport de Rentabilité Immobilière', 105, 20, { align: 'center' });

      // Ligne sous le titre
      doc.setDrawColor(primaryColor);
      doc.line(marginX, 45, 210 - marginX, 45);

      // Section 1 : Données saisies
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(16);
      doc.text('1. Données saisies', marginX, 60);

      const formData = Object.entries(formValues).map(([key, value]) => [
        formatKey(key),
        formatValue(key, value),
      ]);

      doc.autoTable({
        head: [['Champ', 'Valeur']],
        body: formData,
        startY: 65,
        styles: {
          font: 'helvetica',
          fontSize: 12,
          textColor: '#000',
        },
        headStyles: {
          fillColor: primaryColor,
          textColor: '#fff',
        },
        alternateRowStyles: {
          fillColor: '#f3f3f3',
        },
      });

      // Section 2 : Résultats de rentabilité
      const resultsStartY = doc.previousAutoTable.finalY + 10;
      doc.setFont('helvetica', 'bold');
      doc.text('2. Résultats de rentabilité', marginX, resultsStartY);

      const rentabiliteData = Object.entries(rentabilite).map(([key, value]) => [
        formatKey(key), // Utilise keyMapping pour l'indicateur
        formatValue(key, value), // Formate la valeur avec des unités
      ]);

      doc.autoTable({
        head: [['Indicateur', 'Valeur']],
        body: rentabiliteData,
        startY: resultsStartY + 5,
        styles: {
          font: 'helvetica',
          fontSize: 12,
          textColor: '#000',
        },
        headStyles: {
          fillColor: primaryColor,
          textColor: '#fff',
        },
        alternateRowStyles: {
          fillColor: '#f3f3f3',
        },
      });

      // Numérotation des pages et pied de page
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i += 1) {
        doc.setPage(i);
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(10);
        doc.setTextColor('#555555');
        doc.text(
          'Généré sur calculetteimmo.com - Tous droits réservés',
          105,
          footerY,
          { align: 'center' },
        );
        doc.text(`Page ${i} sur ${pageCount}`, 200 - marginX, footerY, { align: 'right' });
      }

      // Télécharger le fichier PDF
      doc.save('Rapport_Rentabilite.pdf');
    });
  };

  return (
    <div className="generate-pdf-container">
      <button
        type="button"
        className="generate-pdf-button"
        onClick={generatePDF}
      >
        Télécharger le rapport PDF
      </button>
    </div>
  );
}

// Définir la validation des props
GeneratePdf.propTypes = {
  formValues: PropTypes.object.isRequired,
  rentabilite: PropTypes.object.isRequired,
};

export default GeneratePdf;
