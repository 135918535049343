/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Button, CardActions, TextField,
} from '@mui/material';

import PropTypes from 'prop-types';
import './calculetteImmo.scss';
import pretBanqueImg from '../../img/pret2.webp';
import notaireImg from '../../img/notaire.webp';
import cup from '../../img/greenPlanet.webp';
import effort from '../../img/effort.webp';
import interetsComposesImg from '../../img/interetsComposesImg.webp';
import getMensualite from '../../Utils/CalculMensualite';
import getCapaciteEmprunt from '../../Utils/CalculCapaciteEmprunt';
import requestTauxInteret from '../../Request/RequestTauxImmoBanDeFrance';
import RadioButtonsGroup from '../../Utils/RadioButtonsGroup';
import getFraisDeNotaire from '../../Utils/CalculFraisDeNotaire';
import calculerInteretsComposes from '../CardInteretCompose/CardInteretCompose';
import Rentabilite from '../NetEffortCalculator/Rentabilite';
import GeneratePdf from '../GeneratePdf/GeneratePdf';

// import ReusableCard from '../../Utils/ReusableCard/Card';

import Loader from '../Loader/Loader';
//

// import NetEffortCalculator from '../NetEffortCalculator/NetEffortCalculator';

function CalculetteImmo({ ...rest }) {
  const [onClickMensualite, setOnClickMensualite] = useState(false);
  const [onClickPrixDuBien, setOnClickPrixDuBien] = useState(false);
  const [capital, setCapital] = useState();
  const [taux, setTaux] = useState();
  const [duree, setDuree] = useState();
  const [mensualite, setMensualite] = useState();
  const [salaire, setSalaire] = useState();
  const [autreEmprunt, setAutreEmprunt] = useState();
  const [onClickCapaciteEmprunt, setOnClickCapaciteEmprunt] = useState(false);
  const [capaciteEmprunt, setCapaciteEmprunt] = useState();
  const [dureeCapacite, setDureeCapacite] = useState();
  const [tauxCapacite, setTauxCapacite] = useState();
  const [tauxInteretBanque, setTauxInteretBanque] = useState(3);
  const [loader, setLoader] = useState(false);
  const [fraisNotaire, setFraisNotaire] = useState();
  const [checked, setChecked] = useState(false);
  // const [checkedPrixduBien, setCheckedPrixduBien] = useState(false);
  const [penteCourbe, setPenteCourbe] = useState(14.5264381);
  const [b, setB] = useState(-26801.2783);
  const [gameOver, setGameOver] = useState(false);
  const [PrixDuBien, setPrixDuBien] = useState(0);
  const [checkedAncienNeuf, setCheckedAncienNeuf] = useState('ancien');
  const [onClickInteretCompose, setOnClickInteretCompose] = useState(false);
  const [capitalFinalInteretCompose, setCapitalFinalInteretCompose] = useState();
  const [capitalInitial, setCapitalInitial] = useState();
  const [epargneMensuelle, setEpargneMensuelle] = useState();
  const [horizonPlacement, setHorizonPlacement] = useState();
  const [tauxInteretAnnuel, setTauxInteretAnnuel] = useState();
  const [intervalleVersement, setIntervalleVersement] = useState();
  const [totalVersements, setTotalVersements] = useState();
  const [totalInterets, setTotalInterets] = useState();
  const [dateTaux, setDateTaux] = useState('moment');
  const [showRentabilite, setShowRentabilite] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [rentabilite, setRentabilite] = useState({}); // Stocke les résultats de rentabilité
  const [showTax, setShowTax] = useState(false);

  const navigate = useNavigate();

  // Pour la card Crypto plus values

  const [formValuesCrypto, setFormValuesCrypto] = useState({
    date: null,
    crypto: null,
    amount: null,
    portfolioValue: null,
  });
  const handleChangeCrypto = (event) => {
    setShowTax(false);
    const { name, value } = event.target;
    setFormValuesCrypto((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleSubmitCrypto = (event) => {
    setShowTax(true);
    event.preventDefault();
    console.log('Calcul de la plus-value avec :', formValuesCrypto);
    // Ajoute ici la logique de calcul
  };
    // FIN Pour la card Crypto plus values

  // pour la card effort et rentrabilite brute nette
  const [formValues, setFormValues] = useState({
    PrixAchat: null,
    apport: null,
    tauxEmprunt: null,
    dureeCredit: null,
    loyer: null,
    chargesRecuperables: null,
    chargesCopropriete: null,
    taxeFonciere: null,
    entretien: null,
    assurance: null,
    coutRenovation: null,
    vacanceLocative: null,
  });

  // Mémoriser la fonction onResults pour éviter qu'elle change à chaque re-rendu
  const handleRentabiliteResults = useCallback((results) => {
    setRentabilite(results);
  }, []);

  const handleChange = (event) => {
    setShowRentabilite(false);
    setShowPdf(false);
    const { name, value } = event.target;
    console.log('dans handleChange', { name, value, type: typeof value });
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: parseFloat(value), // Conversion en nombre pour les calculs
    }));
  };

  const handleSubmitEffort = (event) => {
    event.preventDefault();
    setShowRentabilite(true); // Active l'affichage de Rentabilite
    setOnClickInteretCompose(false);
    setOnClickCapaciteEmprunt(false);
    setOnClickPrixDuBien(false);
    setOnClickMensualite(false);
    setShowPdf(true);

    console.log('hello dans effort submit');
  };

  // fin card effort

  const requestCapitalFinalInteretCompose = async () => {
    const response = calculerInteretsComposes(capitalInitial, epargneMensuelle, tauxInteretAnnuel, horizonPlacement, intervalleVersement);
    setCapitalFinalInteretCompose(response.capitalFinal);
    setTotalVersements(response.totalVersements);
    setTotalInterets(response.totalInterets);
    console.log(response);
  };

  const requestFraisDeNotaire = async () => {
    const response = getFraisDeNotaire(PrixDuBien, checkedAncienNeuf);
    setFraisNotaire(response[0]);
  };

  const requestTaux = async () => {
    setLoader(true);
    const response = await requestTauxInteret();
    if (response && response.status === 200) {
      const taux = response.data.results[0].obs_value;
      setTauxInteretBanque(taux);
      setLoader(false);
      // Date au format "YYYY-MM-DD"
      const dateString = response.data.results[0].time_period_end;
      console.log('date response data', dateString);
      console.log(' response data', response);
      const date = new Date(dateString);
      // Extraire le mois (ajouter 1 car getMonth() renvoie 0 pour janvier)
      const mois = String(date.getMonth() + 1).padStart(2, '0');
      const annee = date.getFullYear();
      // Construire le format MM-YYYY
      const formatMMYYYY = `${mois}-${annee}`;
      setDateTaux(formatMMYYYY);
      console.log('date', formatMMYYYY);
    }
    else {
      navigate('/');
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    requestTaux();
  }, []);

  const mensualiteResult = getMensualite(capital, (taux / 100), (duree * 12));
  const capaciteEmpruntResult = getCapaciteEmprunt(salaire, autreEmprunt, tauxCapacite / 100, dureeCapacite * 12);

  const handleSubmitFormCapaciteEmprumt = (event) => {
    event.preventDefault();
    const resultCapEmp = Math.round(capaciteEmpruntResult);

    if (resultCapEmp < 0) {
      setChecked(false);
      setCapaciteEmprunt('Vous avez dépassé votre capacité d\'emprunt !');
      setGameOver(true);
    }
    else {
      setCapaciteEmprunt(resultCapEmp);
      const resultFrais = ((resultCapEmp - b) / penteCourbe);
      setFraisNotaire(Math.round(resultFrais));
      setChecked(true);
    }
    setOnClickCapaciteEmprunt(true);
    setOnClickPrixDuBien(false);
    setOnClickMensualite(false);
    setOnClickInteretCompose(false);
  };
  const handleChangeChecked = (value) => {
    setChecked(false);
    if (value === 'ancien') {
      setPenteCourbe(14.5264381);
      setB(-26801.2783);
      const result = Math.round(((capaciteEmprunt - (-26801.2783)) / 14.5264381));
      setFraisNotaire(result);
      setChecked(true);
    }
    else if (value === 'neuf') {
      setPenteCourbe(55.8108995);
      setB(-103003.94);

      const result = Math.round(((capaciteEmprunt - (-103003.94)) / 55.8108995));
      setFraisNotaire(result);
      setChecked(true);
    }
  };

  const handleChangePrixDuBienChecked = (value) => {
    setCheckedAncienNeuf(value);
  };
  useEffect(() => {
    requestFraisDeNotaire();
  }, [checkedAncienNeuf]);

  const handleSubmitFormMensualite = (event) => {
    event.preventDefault();
    setOnClickMensualite(true);
    setOnClickPrixDuBien(false);
    setOnClickCapaciteEmprunt(false);
    setOnClickInteretCompose(false);
    setShowRentabilite(false);
    setMensualite(Math.round(mensualiteResult));
    setShowPdf(false);
  };
  const handleSubmitFormPrixDuBien = (event) => {
    event.preventDefault();
    requestFraisDeNotaire();
    setOnClickPrixDuBien(true);
    setOnClickCapaciteEmprunt(false);
    setOnClickInteretCompose(false);
    setOnClickMensualite(false);
    setShowRentabilite(false);
    setShowPdf(false);
  };
  const handleSubmitFormInteretsComposes = (event) => {
    event.preventDefault();
    requestCapitalFinalInteretCompose();
    setOnClickInteretCompose(true);
    setOnClickCapaciteEmprunt(false);
    setOnClickPrixDuBien(false);
    setOnClickMensualite(false);
    setShowRentabilite(false);
    setShowPdf(false);
  };

  const handleChangeCapital = (inputCapital) => {
    setCapital(inputCapital);
    setOnClickMensualite(false);
  };
  const handleChangePrixDuBien = (inputPrixDuBien) => {
    setPrixDuBien(inputPrixDuBien);
    setOnClickPrixDuBien(false);
    // requestFraisDeNotaire();
  };
  const handleChangeTaux = (inputTaux) => {
    setTaux(inputTaux);
    setOnClickMensualite(false);
  };
  const handleChangeDuree = (inputDuree) => {
    setDuree(inputDuree);
    setOnClickMensualite(false);
  };
  const handleChangeSalaire = (inputSalaire) => {
    setSalaire(inputSalaire);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeAutreEmprunt = (inputAutreEmprunt) => {
    setAutreEmprunt(inputAutreEmprunt);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeTauxCapacite = (inputTauxCapacite) => {
    setTauxCapacite(inputTauxCapacite);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeDureeCapacite = (inputDureeCapacite) => {
    setDureeCapacite(inputDureeCapacite);
    setOnClickCapaciteEmprunt(false);
  };
  const handleChangeCapitalInitial = (inputCapitalInitial) => {
    setCapitalInitial(inputCapitalInitial);
    setOnClickInteretCompose(false);
  };
  const handleChangeEpargneMensuelle = (inputEpargneMensuelle) => {
    setEpargneMensuelle(inputEpargneMensuelle);
    setOnClickInteretCompose(false);
  };
  const handleChangeHorizonPlacement = (inputHorizonPlacement) => {
    setHorizonPlacement(inputHorizonPlacement);
    setOnClickInteretCompose(false);
  };
  const handleChangeTauxInteretAnnuel = (inputTauxInteretAnnuel) => {
    setTauxInteretAnnuel(inputTauxInteretAnnuel);
    setOnClickInteretCompose(false);
  };
  const handleChangeIntervalleVersement = (inputIntervalleVersement) => {
    setIntervalleVersement(inputIntervalleVersement);
    setOnClickInteretCompose(false);
  };

  return (
    <div
      className="calculetteImmo"
      {...rest}
    >
      <noscript>
        <p>
          Bienvenue sur Calculette Immo ! Activez JavaScript pour utiliser nos outils de calcul, ou consultez notre <a href="/guide">guide des calculs immobiliers</a>.
        </p>
      </noscript>
      {/* <ReusableCard /> */}
      {/* {loader ? (
        <Loader />
      ) : ( */}
      <Card sx={{ maxWidth: 345, color: '#FFFFFF', backgroundColor: '#18625E' }} className="calculetteImmo-card">
        <form action="" onSubmit={handleSubmitFormMensualite}>
          <CardMedia
            component="img"
            height="240"
            image={pretBanqueImg}
            alt="pret banque"
          />
          <CardContent
            sx={{ color: '#FFFFFF' }}
          >
            <Typography gutterBottom variant="h5" component="div">
              Patrimoine
            </Typography>

            <Typography variant="body3" color="#FFFFFF">
              D'après une somme empruntée, calculez vos mensualités

            </Typography>
            <Typography className="typo" gutterBottom variant="h5" component="div" sx={{ color: '#FFFFFF', fontSize: '1em', fontFamily: 'italic' }}>
              {`Taux Banque de France du ${dateTaux} : ${tauxInteretBanque && tauxInteretBanque}%`}
            </Typography>
            <div className="divFormMensualite">

              <TextField
                className="textfield"
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 5000, step: 1 }}
                autoComplete="off"
                label="Capital à emprunter"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeCapital(event.target.value)}
              />

              <TextField
                id="outlined-disabled"
                required
                type="number"
                autoComplete="off"
                inputProps={{ min: 0, max: 10, step: 0.1 }}
                label="Taux intérêt en %"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeTaux(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                autoComplete="off"
                label="Durée prêt (année)"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeDuree(event.target.value)}
              />

            </div>
          </CardContent>
          <CardActions className="divMensualite">
            <Button
              className="myButton"
              size="small"
              variant="contained"
              type="submit"
              sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
            >
              GO !
            </Button>
            {onClickMensualite && (
            <div
              className="divMensualite-text"
            >
              <div>
                MENSUALITES :
              </div>
              <div>{mensualite} euros</div>
            </div>
            )}
          </CardActions>
        </form>
      </Card>

      <Card sx={{ maxWidth: 345, color: '#FFFFFF', backgroundColor: '#18625E' }} className="calculetteImmo-card">
        <form action="" onSubmit={handleSubmitFormPrixDuBien}>
          <CardMedia
            component="img"
            height="240"
            image={notaireImg}
            alt="pret banque"
          />
          <CardContent
            sx={{ color: '#FFFFFF' }}
          >
            <Typography gutterBottom variant="h5" component="div">
              Patrimoine
            </Typography>
            <Typography variant="body3" color="#FFFFFF">
              D'après un prix d'achat, calculez les frais de Notaires

            </Typography>
            <div className="divFormMensualite">
              <RadioButtonsGroup
                handleChangeFunction={handleChangePrixDuBienChecked}
              />

              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 5000, step: 1 }}
                autoComplete="off"
                label="Prix du bien"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangePrixDuBien(event.target.value)}
              />

            </div>
          </CardContent>
          <CardActions className="divMensualite">
            <Button
              className="myButton"
              size="small"
              variant="contained"
              type="submit"
              sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
            >
              GO !
            </Button>
            {onClickPrixDuBien && (
            <div
              className="divMensualite-text"
            >
              <div>
                Frais de Notaires :
              </div>
              {fraisNotaire} euros

            </div>
            )}
          </CardActions>
        </form>
      </Card>

      <Card sx={{ maxWidth: 345, color: '#FFFFFF', backgroundColor: '#18625E' }} className="calculetteImmo-card">
        <form action="" onSubmit={handleSubmitFormCapaciteEmprumt}>
          <CardMedia
            component="img"
            height="240"
            image={cup}
            alt="pret banque"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Patrimoine
            </Typography>

            <Typography variant="body3" color="#FFFFFF">
              D'après votre salaire, calculez votre capacité d'emprunt

            </Typography>
            <Typography className="typo" gutterBottom variant="h5" component="div" sx={{ color: '#FFFFFF', fontSize: '1em', fontFamily: 'italic' }}>
              {`Taux Banque de France du ${dateTaux} : ${tauxInteretBanque && tauxInteretBanque}%`}
            </Typography>
            <div className="divFormMensualite">
              <RadioButtonsGroup
                handleChangeFunction={handleChangeChecked}
              />

              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, step: 1 }}
                label="Salaire/mois"
                autoComplete="off"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeSalaire(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, step: 1 }}
                autoComplete="off"
                label="Autres Emprunts/mois"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeAutreEmprunt(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                autoComplete="off"
                inputProps={{ min: 0, step: 0.1 }}
                label="Taux intérêt en %"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeTauxCapacite(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, max: 30, step: 1 }}
                autoComplete="off"
                label="Durée prêt (année)"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeDureeCapacite(event.target.value)}
              />

            </div>
          </CardContent>
          <CardActions className="divMensualite">
            <Button
              className="myButton"
              size="small"
              variant="contained"
              type="submit"
              sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
            >
              GO !
            </Button>
            {onClickCapaciteEmprunt && (
            <div
              className="divMensualite-text"
            >
              <div>
                CAPACITE EMPRUNT :
              </div>
              <div>{capaciteEmprunt} {!gameOver && ('euros')} </div>
              {checked && (
              <div>N'oubliez pas les frais de Notaire = {fraisNotaire} euros</div>
              )}

            </div>
            )}
          </CardActions>
        </form>
      </Card>

      <Card sx={{ maxWidth: 345, color: '#FFFFFF', backgroundColor: '#18625E' }} className="calculetteImmo-card">
        <form action="" onSubmit={handleSubmitFormInteretsComposes}>
          <CardMedia
            component="img"
            height="240"
            image={interetsComposesImg}
            alt="Interets Composes"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Patrimoine
            </Typography>
            <Typography variant="body3" color="#FFFFFF">
              Calculez simplement les intérêts composés que vous pouvez générer grâce à vos investissements.

            </Typography>
            <div className="divFormMensualite">

              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, step: 1 }}
                label="Capital initial"
                autoComplete="off"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeCapitalInitial(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, step: 1 }}
                autoComplete="off"
                label="Epargne mensuelle"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeEpargneMensuelle(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                autoComplete="off"
                inputProps={{ min: 0, max: 100, step: 1 }}
                label="Horizon de placement"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeHorizonPlacement(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, max: 50, step: 0.1 }}
                autoComplete="off"
                label="Taux d'intérêt"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeTauxInteretAnnuel(event.target.value)}
              />
              <TextField
                id="outlined-disabled"
                required
                type="number"
                inputProps={{ min: 0, max: 12, step: 1 }}
                autoComplete="off"
                label="Intervalle de versement des intérêts"
                sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                onChange={(event) => handleChangeIntervalleVersement(event.target.value)}
              />

            </div>
          </CardContent>
          <CardActions className="divMensualite">
            <Button
              className="myButton"
              size="small"
              variant="contained"
              type="submit"
              sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A' } }}
            >
              GO !
            </Button>
            {onClickInteretCompose && (
            <div
              className="divMensualite-text"
            >
              <div>
                CAPITAL FINAL :
              </div>
              <div>{`${capitalFinalInteretCompose.toFixed(0)} euros`}
              </div>
              <div
                className="divMensualite-text-versement"
              >
                Versements : {`${totalVersements.toFixed(0)} euros`}
              </div>
              <div
                className="divMensualite-text-versement"
              >
                Interêts : {`${totalInterets.toFixed(0)} euros`}
              </div>

            </div>
            )}
          </CardActions>
        </form>
      </Card>
      {/* CARD EFFORT */}
      <Card sx={{ maxWidth: 345, color: '#FFFFFF', backgroundColor: '#18625E' }} className="calculetteImmo-card">
        <form action="" onSubmit={handleSubmitEffort}>
          <CardMedia
            component="img"
            height="240"
            image={effort}
            alt="Interets Composes"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Patrimoine
            </Typography>
            <Typography variant="body3" color="#FFFFFF">
              Calculez la rentabilité brute et nette de votre investissement immobilier.

            </Typography>
            <Typography className="typo" gutterBottom variant="h5" component="div" sx={{ color: '#FFFFFF', fontSize: '1em', fontFamily: 'italic' }}>
              {`Taux Banque de France du ${dateTaux} : ${tauxInteretBanque && tauxInteretBanque}%`}
            </Typography>
            <div className="divFormMensualite">

              {[
                {
                  id: 'PrixAchat',
                  label: 'Prix d\'achat',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'apport',
                  label: 'Apport',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'tauxEmprunt',
                  label: 'Taux d\'Emprunt',
                  type: 'number',
                  inputProps: { min: 0, max: 10, step: 0.1 },
                },
                {
                  id: 'dureeCredit',
                  label: 'Durée de crédit en année',
                  type: 'number',
                  inputProps: { min: 0, max: 25, step: 1 },
                },
                {
                  id: 'loyer',
                  label: 'Loyer mensuel sans les charges',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },

                {
                  id: 'chargesRecuperables',
                  label: 'Charges Récupérables mensuelles',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'chargesCopropriete',
                  label: 'Charges copropriété mensuelles',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'taxeFonciere',
                  label: 'Taxe foncière mensuelle',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'entretien',
                  label: 'Entretien hors copro mensuel',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'assurance',
                  label: 'Assurance bailleur mensuelle',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'coutRenovation',
                  label: 'Coût de la rénovation estimée',
                  type: 'number',
                  inputProps: { min: 0, step: 1 },
                },
                {
                  id: 'vacanceLocative',
                  label: 'Vacance locative en %',
                  type: 'number',
                  inputProps: { min: 0, max: 20, step: 1 },
                },
              ].map(({
                id, label, type, inputProps, onChange,
              }) => (
                <TextField
                  key={id}
                  id={id}
                  name={id} // Utilisé pour identifier le champ dans `handleChange`
                  required
                  type={type}
                  label={label}
                  inputProps={inputProps}
                  autoComplete="off"
                  value={formValues[id]} // Liaison avec l'état
                  sx={{ margin: '0.5em', backgroundColor: '#FFFFFF', minWidth: '225px' }}
                  onChange={handleChange}
                />
              ))}
            </div>
          </CardContent>
          <CardActions className="divMensualiteCard5">
            {/* {!showRentabilite && ( */}
            <Button
              className="myButtonCard5"
              size="small"
              variant="contained"
              type="submit"
              sx={{ backgroundColor: '#12282A', '&:hover': { backgroundColor: '#FFFFFF', color: '#12282A', display: 'flex' } }}
            >
              GO !
            </Button>

            {showRentabilite && (
            <div
              className="divEffort-text"
            >
              <Rentabilite formValues={formValues} onResults={handleRentabiliteResults} />

            </div>
            )}
            {showPdf && (
            <GeneratePdf formValues={formValues} rentabilite={rentabilite} />
            )}
          </CardActions>
        </form>
      </Card>
      {/* <NetEffortCalculator /> */}
      {/* )} */}
      {/* <CryptoTaxCalculator
        formValues={formValuesCrypto}
        handleChange={handleChangeCrypto}
        handleSubmit={handleSubmitCrypto}
        onTaxVisibility={showTax}
      /> */}

    </div>
  );
}

export default CalculetteImmo;
