import { useEffect, useState } from 'react';
// import EuroIcon from '@mui/icons-material/Euro';
import CalculetteImmo from '../CalculetteImmo/CalculetteImmo';
import Footer from '../Footer/Footer';
import './app.scss';
import myLogo from '../../img/logoP.webp';
import CookieConsent from '../CookieConsent/CookieConsent';
import '../../asset/styles/main.scss';

// import CompteurVisite from '../CompteurVisite/CompteurVisite';
import AdsenseAd from '../AdsenseAd/AdsenseAd';

function App() {
  const [hasConsented, setHasConsented] = useState(null); // État du consentement

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll en haut lors du montage du composant
  }, []);
  return (
    <div className="App">
      {/* <CompteurVisite /> */}
      <header className="App-header">
        <div className="App-header__logo">
          <img
            src={myLogo}
            alt="Logo arrondi"
            style={{
              borderRadius: '50%',
              // border: '2px solid #000',
              width: '50px',
              height: '50px',
            }}
          />

        </div>
        <div className="title-header">
          <h1>Calculette Immo - Votre calculateur d'investissements immobiliers et Cryptomonnaies</h1>

        </div>
        {/* <div className="euroIcon">
          <EuroIcon />
        </div> */}
      </header>
      <CookieConsent onConsentChange={setHasConsented} />
      <div className="container-composants">
        <CalculetteImmo />
        <AdsenseAd />
        <div className="App-footer">
          <Footer hasConsented={hasConsented !== null ? hasConsented : false} />

        </div>

      </div>

    </div>
  );
}

export default App;
